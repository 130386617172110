import { CTA } from 'components/CTA';
import { Layout } from 'components/Layout';
import { PrincipleFeatures } from 'components/Principle/Features';
import { graphql, useStaticQuery } from 'gatsby';
import { InsightHero } from 'insights/Hero';
import React from 'react';
import { MediaSection } from 'src/components/MediaSection';

export const InsightsPage = () => {
    const data = useStaticQuery(query);

    return (
        <Layout
            //pageTitleFull="Audentio Community - Insights"
            pageTitle="Insights"
            siteDescription="We can help grow your community whether you’re on YouTube, a forum, or at a digital conference."
            contactForm
        >
            <InsightHero />
            <MediaSection
                id="reports"
                image={data.chart.childImageSharp.fluid}
                title="Get reports that are meaningful and informative on your community"
                mb="6rem"
            >
                We can create a custom report that includes key performance
                indicators and a variety of other metrics chosen to help measure
                your community and give actionable insights. <br />
                <br />
                With our experience in communities and technology, we can help
                bring meaning to the numbers and suggestions to enhance the
                community.
            </MediaSection>

            <CTA
                title="Want insights for your community?"
                position="relative"
                marginBottom={{ _: 0, full: '-58px' }}
            />

            <PrincipleFeatures
                id="features"
                title="get community guidance through insights"
                features={[
                    {
                        title: 'bring data together',
                        content:
                            'Metrics from various platforms displayed together in one report using Google Data Studio.',
                    },
                    {
                        title: 'key metrics selected',
                        content:
                            'An overview with key metrics chosen to help show the overall performance of the community with additional metrics broken out for more detail.',
                    },
                    {
                        title: 'perspective of community specialists',
                        content:
                            'Our knowledge of communities and technology, acquired from over 10 years in community development, utilized to provide insight into your community.',
                    },
                    {
                        title: 'customized for your community',
                        content:
                            'Created based on your community and can be adapted for specific goals and needs.',
                    },
                    {
                        title: 'get a detailed picture of user activity',
                        content:
                            'Go beyond traditional analytics and understand how your users interact with your community and each other.',
                    },
                    {
                        title: "easily track what's important",
                        content:
                            'Reports are broken out focusing on what is important for communities, letting you get the insights you need quickly and easily.',
                    },
                ]}
            />
        </Layout>
    );
};

export const query = graphql`
    query InsightsPageQuery {
        chart: file(
            relativePath: { eq: "images/insights/chart-platform-insights.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
