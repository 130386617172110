import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { ContentWidth } from 'components/ContentWidth';
import { HeroComponent } from 'components/HeroComponent';
import { Paragraph } from 'components/Paragraph';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const query = graphql`
    query InsightsQuery {
        tabletArt: file(relativePath: { eq: "images/insights/device.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        graphArt: file(relativePath: { eq: "images/insights/line-chart.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const FlairItem = ({
    top,
    bottom,
    left,
    right,
    width,
    img,
    anim,
    animDuration,
    zIndex,
}) => (
    <div
        css={css`
            position: absolute;
            width: ${width};
            top: ${top};
            bottom: ${bottom};
            left: ${left};
            right: ${right};
            animation: ${anim} ${animDuration} ease-in-out infinite;
            z-index: ${zIndex};
        `}
    >
        <img alt="" src={img} style={{ width: '100%' }} />
    </div>
);

const FlairShadow = ({ anim }) => (
    <div
        css={css`
            position: absolute;
            bottom: -6%;
            left: -9%;
            width: 20%;
            height: 6%;
            background: radial-gradient(
                ellipse,
                rgba(0, 0, 0, 0.1) 30%,
                rgba(255, 255, 255, 0) 80%
            );
            background-size: contain;
            animation: ${anim} 10s ease-in-out infinite;
        `}
    ></div>
);

export const InsightHero = () => {
    const data = useStaticQuery(query);

    const floatAnim = keyframes`
        0% {
            transform: translatey(10px);
        }
        50% {
            transform: translatey(-20px);
        }
        100% {
            transform: translatey(10px);
        }
    `;

    const floatReverseAnim = keyframes`
        0% {
        transform: translatey(-20px);
        }
        50% {
            transform: translatey(0px);
        }
        100% {
            transform: translatey(-20px);
        }
    `;

    const floatSmallAnim = keyframes`
        0% {
            transform: translatey(-5px);
        }
        50% {
            transform: translatey(5px);
        }
        100% {
            transform: translatey(-5px);
        }
    `;

    const shadowAnim = keyframes`
        0% {
            transform: scale(1.2);
            opacity: .4;
        }
        50% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(1.2);
            opacity: .4;
        }
    `;

    const HeroContainer = styled(Box)`
        position: relative;
        :after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 33%;
            background-image: linear-gradient(90deg, #fff, #dadada);
            z-index: 0;

            @media (max-width: 992px) {
                display: none;
            }
        }
    `;

    const VideoWrapper = styled(Box)`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :before {
            content: '';
            position: absolute;
            display: block;
            bottom: -4%;
            left: 0;
            right: 0;
            height: 10%;
            background: radial-gradient(
                ellipse,
                rgba(0, 0, 0, 0.2) 40%,
                rgba(255, 255, 255, 0) 72%
            );
            background-size: contain;
            z-index: -1;
        }
    `;

    return (
        <HeroContainer px="20px" overflowX="hidden">
            {/* <Box maxWidth="1600px" mx="auto">  - for wider display of hero*/}
            <ContentWidth>
                <HeroComponent
                    height={{ _: 'auto', lg: '550px' }}
                    p="0"
                    pb={{ _: '80px', md: '0' }}
                    my={{ _: 32, lg: 20 }}
                    backgroundColor="primaryBackground"
                    flexWrap="wrap"
                    flexDirection={{ _: 'column', md: 'column', lg: 'row' }}
                >
                    <Box
                        width={{ _: '100%', md: '100%', lg: '40%' }}
                        style={{
                            flexDirection: 'row',
                            flexGrow: 1,
                            zIndex: 2,
                        }}
                        mb={{ _: '100px', md: '100px', lg: '0' }}
                        pr={{ _: '0', md: '0', lg: '10%' }}
                    >
                        <Box
                            color="boldText"
                            fontWeight="medium"
                            fontSize="heroHeading"
                            as="h1"
                            mb={10}
                            lineHeight="shorter"
                        >
                            gain insight into your community.
                        </Box>

                        <Paragraph
                            lineHeight={['tall', null, 'taller']}
                            color="boldText"
                            mb={10}
                        >
                            Get a deeper understanding of your community through
                            metrics and acquire valuable insight into the
                            community to help make important decisions.
                        </Paragraph>

                        <Button href="/contact?source=insightpage">
                            Get started
                        </Button>
                    </Box>

                    <Box
                        width={{ _: '100%', md: '100%', lg: '50%' }}
                        zIndex="5"
                        position="relative"
                    >
                        <Img //Table BG
                            alt=""
                            fluid={data.tabletArt.childImageSharp.fluid}
                            style={{
                                height: '100%',
                                //width: '100%',
                                margin: '0 5%',
                            }}
                        />

                        <VideoWrapper>
                            <video
                                style={{
                                    backfaceVisibility: 'hidden',
                                    borderRadius: '5px',
                                    backgroundColor: '#fff',
                                    width: '80%',
                                }}
                                muted
                                autoPlay
                                loop
                                poster="/videos/insights-hero-poster.jpg"
                            >
                                <source
                                    src={'/videos/platform-insights.mp4'}
                                    type="video/mp4"
                                />
                            </video>

                            <FlairItem
                                width="22%"
                                top="auto"
                                bottom="12%"
                                left="-8.3%"
                                right="auto"
                                img="/insights/pie-chart.png"
                                anim={floatReverseAnim}
                                animDuration="10s"
                                zIndex="20"
                            />
                            <FlairShadow anim={shadowAnim} />

                            <FlairItem
                                width="15%"
                                top="5%"
                                bottom="auto"
                                left="-12%"
                                right="auto"
                                img="/insights/blue-piece.png"
                                anim={floatAnim}
                                animDuration="12s"
                                zIndex="1"
                            />

                            <FlairItem
                                width="17%"
                                top="-19%"
                                bottom="auto"
                                left="auto"
                                right="16%"
                                img="/insights/green-piece.png"
                                anim={floatSmallAnim}
                                animDuration="7s"
                                zIndex="-1"
                            />

                            <FlairItem
                                width="14%"
                                top="45%"
                                bottom="auto"
                                left="auto"
                                right="-12%"
                                img="/insights/globe.png"
                                anim={floatAnim}
                                animDuration="8s"
                                zIndex="-1"
                            />

                            <Box
                                style={{
                                    position: 'absolute',
                                    bottom: '-12%',
                                    right: '-12%',
                                    width: '40%',
                                }}
                            >
                                <Img
                                    alt=""
                                    fluid={data.graphArt.childImageSharp.fluid}
                                />
                            </Box>
                        </VideoWrapper>
                    </Box>
                </HeroComponent>
            </ContentWidth>
            {/* </Box> */}
        </HeroContainer>
    );
};
