import { Box, BoxProps } from 'components/Box';
import { Button } from 'components/Button';
import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { ThemeProvider } from 'components/ThemeProvider';
import React from 'react';

export function CTA({
    title,
    href = '/contact',
    buttonText = 'Contact us',
    ...props
}: {
    title: string,
    href: string,
    buttonText?: string,
} & BoxProps) {
    return (
        <ContentWidth px={{ _: 0 }} {...props}>
            <ThemeProvider defaultMode="dark">
                <Flex
                    backgroundColor="primaryBackground"
                    direction={{ _: 'column', md: 'row' }}
                    py={16}
                    px={{ _: 4, md: 16 }}
                    justify="center"
                >
                    <Heading
                        mr={{_: '0', md: '10'}}
                        mb={0}
                        fontSize="subsectionHeading"
                        as="h3"
                    >
                        {title}
                    </Heading>

                    <Box mt={{ _: 10, md: 0 }}>
                        <Button fill="white" href={href}>
                            {buttonText}
                        </Button>
                    </Box>
                </Flex>
            </ThemeProvider>
        </ContentWidth>
    );
}
